(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@cassette/core"), require("react"));
	else if(typeof define === 'function' && define.amd)
		define(["@cassette/core", "react"], factory);
	else if(typeof exports === 'object')
		exports["cassetteHooks"] = factory(require("@cassette/core"), require("react"));
	else
		root["cassetteHooks"] = factory(root["cassetteCore"], root["React"]);
})((typeof self !== "undefined" ? self : this), function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__) {
return 